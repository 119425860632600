import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, BINARY_GENDER_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, BinaryGenderTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption, BinaryGenderType } from "../../../types";
import { BaseColumnChart } from "../base-column-chart";

export type BaseBinaryGenderTypeColumnData = {
  binaryGenderType: BinaryGenderType;
} & BaseData;

export type BaseBinaryGenderTypeColumnChartSeriesCode = `${AggregateType}_${BinaryGenderType}`;

export class BaseBinaryGenderTypeColumnChart extends BaseColumnChart<BaseBinaryGenderTypeColumnData> {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseBinaryGenderTypeColumnData> | ChartQueryResult<BaseBinaryGenderTypeColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDef(t)),
    };
  }

  protected _getSeriesCode(datum: BaseBinaryGenderTypeColumnData): BaseBinaryGenderTypeColumnChartSeriesCode {
    return `${datum.aggregateType}_${datum.binaryGenderType}`;
  }

  protected _createSeriesDef(t: TFunction): Map<BaseBinaryGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption> {
    const results = new Map<BaseBinaryGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption>();
    AGGREGATE_TYPES.forEach((aggregateType) => {
      BINARY_GENDER_TYPES.forEach((binaryGenderType, i) => {
        results.set(`${aggregateType}_${binaryGenderType}`, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            BinaryGenderTypeTextDef.get(binaryGenderType) as string
          )}`,
          color: super.getColor(aggregateType, i),
        });
      });
    });
    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseBinaryGenderTypeColumnData> | ChartQueryResult<BaseBinaryGenderTypeColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: BINARY_GENDER_TYPES.map((binaryGenderType) => {
        return {
          header: t(BinaryGenderTypeTextDef.get(binaryGenderType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.binaryGenderType === binaryGenderType
          ),
        };
      }),
    }));
  }
}
