import {
  AggregateType,
  BoardType,
  Chart,
  ChartType,
  EmployeeType,
  GenderHeadcountType,
  GenderRatioType,
  BinaryGenderType,
  GenderType,
  Unit,
  BinaryGenderHeadcountType,
  GenderRateType,
  AnnualEmployedCompositionRatioType,
  AnnualEmployedCompositionHeadcountType,
  EmploymentType,
  CalcBasisItem,
  HeadcountAndRateType,
  EmployeeGenderType,
  GradeType,
  DisabledEmploymentHeadcountAndRateType,
  DisabledEmploymentType,
  DaysAndRateType,
} from "../types";

export const BoardTypeTextDef = new Map<BoardType, string>([["preset", "dashboard.board-type.preset"]]);

export const ChartTextDef = new Map<Chart, string>([
  ["gender_pay_gap", "dashboard.chart.gender-pay-gap"],
  ["female_manager_ratio", "dashboard.chart.female-manager-ratio"],
  ["male_childcare_leave_rate", "dashboard.chart.male-childcare-leave-rate"],
  ["male_childcare_leave_rate_by_employee_type", "dashboard.chart.male-childcare-leave-rate-by-employee-type"],
  [
    "male_childcare_leave_or_childcare_purpose_leave_rate",
    "dashboard.chart.male-childcare-leave-or-childcare-purpose-leave-rate",
  ],
  ["gender_composition_of_all_employee", "dashboard.chart.gender-composition-of-all-employee"],
  ["gender_composition_of_regular_employee", "dashboard.chart.gender-composition-of-regular-employee"],
  ["gender_composition_of_non_regular_employee", "dashboard.chart.gender-composition-of-non-regular-employee"],
  ["full_time_employee_headcount_by_gender", "dashboard.chart.full-time-employee-headcount-by-gender"],
  ["full_time_employee_headcount_by_employee_type", "dashboard.chart.full-time-employee-headcount-by-employee-type"],
  ["average_age_of_all_employee_by_gender", "dashboard.chart.average-age-of-all-employee-by-gender"],
  ["average_age_of_regular_employee_by_gender", "dashboard.chart.average-age-of-regular-employee-by-gender"],
  ["age_distribution_of_regular_employee_by_gender", "dashboard.chart.age-distribution-of-regular-employee-by-gender"],
  ["age_distribution_of_manager_by_gender", "dashboard.chart.age-distribution-of-manager-by-gender"],
  ["average_service_year_of_all_employee_by_gender", "dashboard.chart.average-service-year-of-all-employee-by-gender"],
  [
    "average_service_year_of_regular_employee_by_gender",
    "dashboard.chart.average-service-year-of-regular-employee-by-gender",
  ],
  [
    "average_service_year_of_non_regular_employee_by_gender",
    "dashboard.chart.average-service-year-of-non-regular-employee-by-gender",
  ],
  [
    "full_time_employee_average_service_year_by_gender",
    "dashboard.chart.full-time-employee-average-service-year-by-gender",
  ],
  [
    "gender_average_service_year_gap_of_all_employee",
    "dashboard.chart.gender-average-service-year-gap-of-all-employee",
  ],
  [
    "gender_average_service_year_gap_of_regular_employee",
    "dashboard.chart.gender-average-service-year-gap-of-regular-employee",
  ],
  [
    "gender_average_service_year_gap_of_non_regular_employee",
    "dashboard.chart.gender-average-service-year-gap-of-non-regular-employee",
  ],
  [
    "full_time_employee_gender_average_service_year_gap",
    "dashboard.chart.full-time-employee-gender-average-service-year-gap",
  ],
  [
    "annual_employed_composition_of_all_employee_by_gender",
    "dashboard.chart.annual-employed-composition-of-all-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_gender",
    "dashboard.chart.annual-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_non_regular_employee_by_gender",
    "dashboard.chart.annual-employed-composition-of-non-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_employment_type",
    "dashboard.chart.annual-employed-composition-of-regular-employee-by-employment-type",
  ],
  [
    "annual_new_graduate_employed_composition_of_regular_employee_by_gender",
    "dashboard.chart.annual-new-graduate-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_mid_career_employed_composition_of_regular_employee_by_gender",
    "dashboard.chart.annual-mid-career-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "mid_career_employed_headcount_and_rate_of_all_employee",
    "dashboard.chart.mid-career-employed-headcount-and-rate-of-all-employee",
  ],
  [
    "mid_career_employed_headcount_and_rate_of_manager",
    "dashboard.chart.mid-career-employed-headcount-and-rate-of-manager",
  ],
  [
    "mid_career_employed_executive_headcount_and_rate",
    "dashboard.chart.mid-career-employed-executive-headcount-and-rate",
  ],
  [
    "separated_headcount_and_rate_of_regular_employee_by_gender",
    "dashboard.chart.separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  [
    "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender",
    "dashboard.chart.personal-reason-separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  ["executive_gender_composition", "dashboard.chart.executive-gender-composition"],
  ["childcare_leave_rate_of_all_employee_by_gender", "dashboard.chart.childcare-leave-rate-of-all-employee-by-gender"],
  [
    "childcare_leave_rate_of_regular_employee_by_gender",
    "dashboard.chart.childcare-leave-rate-of-regular-employee-by-gender",
  ],
  [
    "childcare_leave_rate_of_non_regular_employee_by_gender",
    "dashboard.chart.childcare-leave-rate-of-non-regular-employee-by-gender",
  ],
  ["foreign_employee_headcount_by_nationality", "dashboard.chart.foreign-employee-headcount-by-nationality"],
  ["foreign_employee_headcount_by_gender", "dashboard.chart.foreign-employee-headcount-by-gender"],
  [
    "foreign_employee_headcount_and_rate_of_all_employee",
    "dashboard.chart.foreign-employee-headcount-and-rate-of-all-employee",
  ],
  ["foreign_employee_headcount_and_rate_of_manager", "dashboard.chart.foreign-employee-headcount-and-rate-of-manager"],
  [
    "foreign_new_graduate_employed_headcount_and_rate",
    "dashboard.chart.foreign-new-graduate-employed-headcount-and-rate",
  ],
  ["foreign_executive_headcount_and_rate", "dashboard.chart.foreign-executive-headcount-and-rate"],
  ["disabled_employment_headcount_and_rate", "dashboard.chart.disabled-employment-headcount-and-rate"],
  ["disabled_employment_shortage_headcount", "dashboard.chart.disabled-employment-shortage-headcount"],
  [
    "average_annual_salary_of_regular_employee_by_gender",
    "dashboard.chart.average-annual-salary-of-regular-employee-by-gender",
  ],
  [
    "average_annual_bonus_of_regular_employee_by_gender",
    "dashboard.chart.average-annual-bonus-of-regular-employee-by-gender",
  ],
  [
    "average_annual_bonus_of_regular_employee_by_grade_type",
    "dashboard.chart.average-annual-bonus-of-regular-employee-by-grade-type",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee",
    "dashboard.chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager",
    "dashboard.chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager",
    "dashboard.chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee",
    "dashboard.chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager",
    "dashboard.chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager",
    "dashboard.chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_all_employee",
    "dashboard.chart.average-annual-paid-leave-consumed-days-and-rate-of-all-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee",
    "dashboard.chart.average-annual-paid-leave-consumed-days-and-rate-of-regular-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee",
    "dashboard.chart.average-annual-paid-leave-consumed-days-and-rate-of-non-regular-employee",
  ],
]);

export const ChartUnitTextDef = new Map<Unit, string>([
  ["percent", "percent"],
  ["headcount", "headcount"],
  ["thousand_yen", "thousand-yen"],
  ["age", "age"],
  ["hours", "hours"],
  ["days", "days"],
  ["years", "years"],
]);

export const ChartTypeTextDef = new Map<ChartType, string>([
  ["graph", "dashboard.chart-type.graph"],
  ["table", "dashboard.chart-type.table"],
]);

export const AggregateTypeTextDef = new Map<AggregateType, string>([
  ["our_company", "dashboard.aggregate-type.our-company"],
  ["industry_average", "dashboard.aggregate-type.industry-average"],
  ["all_industry_average", "dashboard.aggregate-type.all-industry-average"],
]);

export const CalcBasisItemTextDef = new Map<CalcBasisItem, string>([
  ["average_annual_payment", "dashboard.calc-basis.average-annual-payment"],
  ["total_payment", "dashboard.calc-basis.total-payment"],
  ["employee_count", "dashboard.calc-basis.employee-count"],
  ["female_manager_headcount", "dashboard.calc-basis.female-manager-headcount"],
  ["manager_headcount", "dashboard.calc-basis.manager-headcount"],
  ["male_employee_taking_childcare_leave_count", "dashboard.calc-basis.male-employee-taking-childcare-leave-count"],
  ["male_employee_with_spouse_gave_birth_count", "dashboard.calc-basis.male-employee-with-spouse-gave-birth-count"],
]);

export const ParentRowTextDef = new Map<AggregateType | CalcBasisItem, string>([
  ...Array.from(AggregateTypeTextDef.entries()),
  ...Array.from(CalcBasisItemTextDef.entries()),
]);

export const EmployeeTypeTextDef = new Map<EmployeeType, string>([
  ["all", "dashboard.employee-type.all"],
  ["regular", "dashboard.employee-type.regular"],
  ["non_regular", "dashboard.employee-type.non-regular"],
]);

export const EmploymentTypeTextDef = new Map<EmploymentType, string>([
  ["all", "dashboard.employment-type.all"],
  ["new_graduate", "dashboard.employment-type.new-graduate"],
  ["mid_career", "dashboard.employment-type.mid-career"],
  ["other", "dashboard.employment-type.other"],
]);

export const EmploymentHeadcountTypeTextDef = new Map<AnnualEmployedCompositionHeadcountType, string>([
  ["all_headcount", "dashboard.employment-headcount-type.all"],
  ["new_graduate_headcount", "dashboard.employment-headcount-type.new-graduate"],
  ["mid_career_headcount", "dashboard.employment-headcount-type.mid-career"],
  ["other_headcount", "dashboard.employment-headcount-type.other"],
]);

export const EmploymentRatioTypeTextDef = new Map<AnnualEmployedCompositionRatioType, string>([
  ["new_graduate_ratio", "dashboard.employment-ratio-type.new-graduate"],
  ["mid_career_ratio", "dashboard.employment-ratio-type.mid-career"],
  ["other_ratio", "dashboard.employment-ratio-type.other"],
]);

export const BinaryGenderTypeTextDef = new Map<BinaryGenderType, string>([
  ["male", "dashboard.gender-type.male"],
  ["female", "dashboard.gender-type.female"],
]);

export const GenderTypeTextDef = new Map<GenderType, string>([
  ["all", "dashboard.gender-type.all"],
  ...Array.from(BinaryGenderTypeTextDef.entries()),
]);

export const BinaryGenderHeadcountTypeTextDef = new Map<BinaryGenderHeadcountType, string>([
  ["male_headcount", "dashboard.gender-headcount-type.male"],
  ["female_headcount", "dashboard.gender-headcount-type.female"],
]);

export const GenderHeadcountTypeTextDef = new Map<GenderHeadcountType, string>([
  ["all_headcount", "dashboard.gender-headcount-type.all"],
  ...Array.from(BinaryGenderHeadcountTypeTextDef.entries()),
]);

export const GenderRatioTypeTextDef = new Map<GenderRatioType, string>([
  ["male_ratio", "dashboard.gender-ratio-type.male"],
  ["female_ratio", "dashboard.gender-ratio-type.female"],
]);

export const GenderRateTypeTextDef = new Map<GenderRateType, string>([
  ["all_rate", "dashboard.gender-rate-type.all"],
  ["male_rate", "dashboard.gender-rate-type.male"],
  ["female_rate", "dashboard.gender-rate-type.female"],
]);

export const HeadcountAndRateTypeTextDef = new Map<HeadcountAndRateType, string>([
  ["headcount", "dashboard.headcount-and-rate-type.headcount"],
  ["rate", "dashboard.headcount-and-rate-type.rate"],
]);

export const DaysAndRateTypeTextDef = new Map<DaysAndRateType, string>([
  ["days", "dashboard.days-and-rate-type.days"],
  ["rate", "dashboard.days-and-rate-type.rate"],
]);

export const GenderEmployeeTypeTextDef = new Map<EmployeeGenderType, string>([
  ["all_male", "dashboard.calc-basis.employee-gender-type.all-male"],
  ["all_female", "dashboard.calc-basis.employee-gender-type.all-female"],
  ["regular_male", "dashboard.calc-basis.employee-gender-type.regular-male"],
  ["regular_female", "dashboard.calc-basis.employee-gender-type.regular-female"],
  ["non_regular_male", "dashboard.calc-basis.employee-gender-type.non-regular-male"],
  ["non_regular_female", "dashboard.calc-basis.employee-gender-type.non-regular-female"],
]);

export const GradeTypeTextDef = new Map<GradeType, string>([
  ["division_manager", "dashboard.grade-type.division-manager"],
  ["section_manager", "dashboard.grade-type.section-manager"],
  ["staff", "dashboard.grade-type.staff"],
]);

export const DisabledEmploymentTypeTextDef = new Map<DisabledEmploymentType, string>([
  ["physical", "dashboard.disabled-employment-type.physical"],
  ["intellectual", "dashboard.disabled-employment-type.intellectual"],
  ["mental", "dashboard.disabled-employment-type.mental"],
]);

export const DisabledEmploymentHeadcountAndRateTypeTextDef = new Map<DisabledEmploymentHeadcountAndRateType, string>([
  [
    "severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "non_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_non_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "severe_physical_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "physical_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.physical-disabled-employee-headcount",
  ],
  [
    "new_employed_physical_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-physical-disabled-employee-headcount",
  ],

  [
    "severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "non_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_non_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],

  [
    "severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "intellectual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.intellectual-disabled-employee-headcount",
  ],
  [
    "new_employed_intellectual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-intellectual-disabled-employee-headcount",
  ],
  [
    "mental_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-over-30h-headcount",
  ],
  [
    "mental_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "mental_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "mental_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-headcount",
  ],
  ["disabled_employee_headcount", "dashboard.disabled-employment-headcount-and-rate-type.disabled-employee-headcount"],
  [
    "new_employed_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-disabled-employee-headcount",
  ],
  [
    "visual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.visual-disabled-employee-headcount",
  ],
  [
    "auditory_balance_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.auditory-balance-disabled-employee-headcount",
  ],
  [
    "speech_language_mastication_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.speech-language-mastication-disabled-employee-headcount",
  ],
  [
    "physical_impaired_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.physical-impaired-employee-headcount",
  ],
  [
    "internal_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.internal-disabled-employee-headcount",
  ],
  ["disabled_employment_rate", "dashboard.disabled-employment-headcount-and-rate-type.disabled-employment-rate"],
]);
