import { TFunction } from "i18next";
import { CalcBasisQueryResult } from "../dashboard-api";
import { CalcBasisItemRow, CalcBasisBaseData } from "../types";
import { IBaseCalcBasis } from "./i-base-calc-basis";

export abstract class BaseCalcBasis<TData extends CalcBasisBaseData> implements IBaseCalcBasis<TData> {
  constructor(private _title: string, private _unitCaption?: string, private _notes?: string[]) {}
  get unitCaption() {
    return this._unitCaption;
  }

  get notes() {
    return this._notes;
  }

  abstract getRows(
    t: TFunction,
    queryResult: CalcBasisQueryResult<TData> | CalcBasisQueryResult<TData>[]
  ): CalcBasisItemRow[];

  /**
   * getRowsの実装で利用する関数
   */
  protected getValuesByCondition<TData extends CalcBasisBaseData>(
    queryResult: CalcBasisQueryResult<TData>,
    condition: (datum: TData) => boolean
  ): (number | null)[] {
    const indexes = queryResult.datasets.map(({ index }) => index);
    return indexes.map((index) => {
      const dataset = queryResult.datasets.find((dataset) => dataset.index === index);
      const datum = dataset?.data.find(condition);
      return datum ? datum.value : null;
    });
  }
}
