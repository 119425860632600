import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../../components/dashboard-chart-display-switch";
import { EMPLOYEE_TYPES } from "../../../../config/const";
import { EmployeeTypeTextDef } from "../../../../config/text-def";
import { ChartQueryResult } from "../../../../dashboard-api";
import { EmployeeType } from "../../../../types";
import { BaseEmployeeTypeLineChart, BaseEmployeeTypeLineData } from "../base-employee-type-line-chart";

export class GenderPayGapChart extends BaseEmployeeTypeLineChart {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseEmployeeTypeLineData> | ChartQueryResult<BaseEmployeeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch),
      series: super.getSeries(
        queryResult,
        displaySwitch,
        this._getSeriesCode,
        this._createSeriesDefs(t),
        this._filterByDisplayValues
      ),
    };
  }

  private _filterByDisplayValues(
    data: BaseEmployeeTypeLineData[],
    displayValues: string[]
  ): BaseEmployeeTypeLineData[] {
    if (displayValues.length > 0) {
      return data.filter(({ employeeType }) => {
        return displayValues.includes(employeeType);
      });
    }
    // 表示する値に何も選択されていなかったら、そのまま返す
    return data;
  }

  getDisplayValues(
    t: TFunction,
    queryResult: ChartQueryResult<BaseEmployeeTypeLineData> | ChartQueryResult<BaseEmployeeTypeLineData>[]
  ): Map<EmployeeType, string> {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    const results = new Map<EmployeeType, string>();
    queryResult.datasets.forEach((dataset) => {
      dataset.data.forEach(({ employeeType }) => {
        if (EMPLOYEE_TYPES.includes(employeeType)) {
          results.set(employeeType, t(EmployeeTypeTextDef.get(employeeType) as string));
        }
      });
    });
    return results;
  }
}
