import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { MyTooltip } from "../../../components/my-tooltip";
import { NotFound } from "../../../components/not-found";
import { AuthStore } from "../../../stores/auth-store";
import { WorkspaceNewForm } from "../components/workspace-new-form";
import { WorkspaceSummary } from "../components/workspace-summary";
import { workspaceApi } from "../workspace-api";

export const Workspace = () => {
  const { t } = useTranslation();

  const workspacesQuery = workspaceApi.useWorkspaces();
  const companiesQuery = workspaceApi.useCompanies();

  const { isTenantOwner } = AuthStore.useContainer();

  const [newFormOpen, setNewFormOpen] = useState<boolean>(false);

  if (workspacesQuery.isLoading || companiesQuery.isLoading)
    return (
      <TenantLayout activeKey="workspace">
        <Grid container>
          <Grid item sm={12} md={10} lg={8}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!workspacesQuery.data) return null;

  const companyExists = Boolean(companiesQuery.data?.length);

  const onClickNew = () => {
    setNewFormOpen(true);
  };

  const renderWorkspacesContainer = () => (
    <>
      {!companyExists && renderNoCompanyContainer()}
      <Grid container item>
        <Grid item sm={12} md={10} lg={8}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MyTooltip unauthorized={!isTenantOwner}>
                <Button variant="contained" onClick={onClickNew} disabled={!companyExists || !isTenantOwner}>
                  {t("workspace.new")}
                </Button>
              </MyTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item sm={12} md={10} lg={8}>
          {workspacesQuery.data.map((workspace) => {
            return (
              <Box key={workspace.id} mb={1}>
                <WorkspaceSummary workspace={workspace} />
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </>
  );

  const renderNoWorkspaceContainer = () => (
    <>
      {!companyExists && renderNoCompanyContainer()}
      <Grid container item>
        <Grid item sm={12} md={10} lg={8}>
          <NotFound
            title={t("workspace.not-found")}
            caption={t("workspace.not-found.caption")}
            buttonLabel={t("workspace.new")}
            buttonUnauthorized={!isTenantOwner}
            disabled={!companyExists || !isTenantOwner}
            onClick={onClickNew}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderNoCompanyContainer = () => (
    <Grid container item>
      <Grid item sm={12} md={10} lg={8}>
        <Alert severity="warning">
          <Trans i18nKey="alert.workspace.warning" components={{ l: <Link to="/companies" /> }} />
        </Alert>
      </Grid>
    </Grid>
  );

  return (
    <TenantLayout activeKey="workspace">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography variant="h6">{t("workspace")}</Typography>
            <Typography variant="caption">{t("workspace.caption")}</Typography>
          </Grid>
        </Grid>
        {workspacesQuery.data.length ? renderWorkspacesContainer() : renderNoWorkspaceContainer()}
      </Grid>
      {newFormOpen && <WorkspaceNewForm open={newFormOpen} onClose={() => setNewFormOpen(false)} />}
    </TenantLayout>
  );
};
