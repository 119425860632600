import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "../../stores/auth-store";
import { TenantStore } from "../../stores/tenant-store";
import { HEADER_HEIGHT } from "./global-header";

const TENANT_SIDEBAR_WIDTH = 240;
const TENANT_SIDEBAR_CLOSED_WIDTH = 56;

type StyledPaperProps = {
  open: boolean;
};

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open", // 'open' プロパティが DOM に渡らないようにする
})<StyledPaperProps>(({ theme, open }) => ({
  width: open ? TENANT_SIDEBAR_WIDTH : TENANT_SIDEBAR_CLOSED_WIDTH,
  minWidth: open ? TENANT_SIDEBAR_WIDTH : TENANT_SIDEBAR_CLOSED_WIDTH,
  height: `calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px)`,
  border: "none",
  borderRadius: 0,
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  overflow: "hidden",
  transition: "width 0.5s, min-width 0.5s",
  boxShadow: "none",
}));

type StyledListItemTextProps = {
  open: boolean;
};

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "open",
})<StyledListItemTextProps>(({ open }) => ({
  opacity: open ? 1 : 0,
  transition: "opacity 0.5s",
}));

export type TenantSidebarKey = "dashboard" | "member" | "workspace" | "company" | "business-year" | "general-setting";

type Item = {
  key: TenantSidebarKey;
  path: string;
  icon: React.ReactNode;
};

const MAIN_ITEMS: Item[] = [
  {
    key: "dashboard",
    path: "/",
    icon: <DashboardIcon />,
  },
  {
    key: "workspace",
    path: "/workspaces",
    icon: <WorkspacesIcon />,
  },
  {
    key: "member",
    path: "/members",
    icon: <GroupIcon />,
  },
];

const SETTING_ITEMS: Item[] = [
  {
    key: "company",
    path: "/companies",
    icon: <BusinessIcon />,
  },
  {
    key: "business-year",
    path: "/business-years",
    icon: <CalendarMonthIcon />,
  },
  {
    key: "general-setting",
    path: "/settings",
    icon: <SettingsIcon />,
  },
];

export const TENANT_SIDEBAR_ITEMS: Item[] = [...MAIN_ITEMS, ...SETTING_ITEMS];

export type TenantSidebarProps = {
  activeKey: TenantSidebarKey;
};

export const TenantSidebar = ({ activeKey }: TenantSidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isSidebarOpen, setIsSidebarOpen } = TenantStore.useContainer();
  const { currentTenant } = AuthStore.useContainer();
  if (!currentTenant) return null;

  const toggleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const onClickItem = (item: Item) => {
    navigate(item.path);
  };

  return (
    <StyledPaper open={isSidebarOpen}>
      <List dense>
        <ListItem>
          <StyledListItemText
            primary={currentTenant.name}
            open={isSidebarOpen}
            // StyledPaperで折り返しを禁止しているめ、上書きで折り返しを許可する
            // 207pxは、Sidebarの幅 - ListItemのpadding
            sx={{ whiteSpace: "normal", minWidth: 207 }}
          />
        </ListItem>
      </List>
      <Divider />
      <List dense>
        {MAIN_ITEMS.map((item) => (
          <ListItem key={item.key} disablePadding onClick={() => onClickItem(item)}>
            <ListItemButton selected={item.key === activeKey}>
              <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.key)} />
            </ListItemButton>
          </ListItem>
        ))}
        <Box mt={1}>
          <ListItem>
            <StyledListItemText
              primary={t("setting")}
              primaryTypographyProps={{ variant: "caption" }}
              open={isSidebarOpen}
            />
          </ListItem>
        </Box>
        {SETTING_ITEMS.map((item) => (
          <ListItem key={item.key} disablePadding onClick={() => onClickItem(item)}>
            <ListItemButton selected={item.key === activeKey}>
              <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.key)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }}></Box>
      <List dense>
        <ListItem disablePadding onClick={toggleOpen}>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 40 }}>
              {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </StyledPaper>
  );
};
