import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, DaysAndRateTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  DaysAndRateType,
  DaysType,
  RateType,
} from "../../../types";
import { BaseColumnLineChart } from "../base-column-line-chart";

export type BaseDaysAndRateTypeColumnLineData = {
  daysAndRateType: DaysAndRateType;
} & BaseData;

export type BaseDaysAndRateTypeColumnLineChartSeriesCode = `${AggregateType}_${DaysAndRateType}`;

type DaysChartSeriesCode = `${AggregateType}_${DaysType}`;
type RateChartSeriesCode = `${AggregateType}_${RateType}`;

export class BaseDaysAndRateTypeColumnLineChart extends BaseColumnLineChart<BaseDaysAndRateTypeColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseDaysAndRateTypeColumnLineData>
      | ChartQueryResult<BaseDaysAndRateTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(datum: BaseDaysAndRateTypeColumnLineData): BaseDaysAndRateTypeColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.daysAndRateType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<DaysChartSeriesCode, ChartSeriesColumnOption>,
    Map<RateChartSeriesCode, ChartSeriesLineOption>,
    BaseDaysAndRateTypeColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<DaysChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<RateChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseDaysAndRateTypeColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      const rateChartSeriesCode: RateChartSeriesCode = `${aggregateType}_rate`;
      orderedSeriesCodes.push(rateChartSeriesCode);
      lineMap.set(rateChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          DaysAndRateTypeTextDef.get("rate") as string
        )}`,
        color: super.getLineColor(aggregateType, 0),
        dashStyle: super.getDashStyle(aggregateType),
      });

      const daysChartSeriesCode: DaysChartSeriesCode = `${aggregateType}_days`;
      orderedSeriesCodes.push(daysChartSeriesCode);
      columnMap.set(daysChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          DaysAndRateTypeTextDef.get("days") as string
        )}`,
        color: super.getColumnColor(aggregateType, 0),
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseDaysAndRateTypeColumnLineData>
      | ChartQueryResult<BaseDaysAndRateTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          {
            header: t(DaysAndRateTypeTextDef.get("days") as string) as string,
            unit: columnQueryResult.unit,
            values: super.getValuesByCondition(
              columnQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.daysAndRateType === "days"
            ),
          },
          {
            header: t(DaysAndRateTypeTextDef.get("rate") as string) as string,
            unit: lineQueryResult.unit,
            values: super.getValuesByCondition(
              lineQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.daysAndRateType === "rate"
            ),
          },
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
