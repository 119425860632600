import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, GENDER_HEADCOUNT_TYPES, GENDER_RATE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, GenderHeadcountTypeTextDef, GenderRateTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  GenderHeadcountType,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  GenderRateType,
  GenderHeadcountAndRateType,
} from "../../../types";
import { BaseStackedColumnLineChart } from "../base-stacked-column-line-chart";

export type BaseGenderHeadcountAndRateTypeStackedColumnLineData = {
  genderHeadcountAndRateType: GenderHeadcountAndRateType;
} & BaseData;

export type BaseGenderHeadcountAndRateTypeStackedColumnLineChartSeriesCode =
  `${AggregateType}_${GenderHeadcountAndRateType}`;

type GenderHeadcountChartSeriesCode = `${AggregateType}_${GenderHeadcountType}`;
type GenderRatioChartSeriesCode = `${AggregateType}_${GenderRateType}`;

export class BaseGenderHeadcountAndRateTypeStackedColumnLineChart extends BaseStackedColumnLineChart<BaseGenderHeadcountAndRateTypeStackedColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseGenderHeadcountAndRateTypeStackedColumnLineData>
      | ChartQueryResult<BaseGenderHeadcountAndRateTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(
    datum: BaseGenderHeadcountAndRateTypeStackedColumnLineData
  ): BaseGenderHeadcountAndRateTypeStackedColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.genderHeadcountAndRateType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<GenderHeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<GenderRatioChartSeriesCode, ChartSeriesLineOption>,
    BaseGenderHeadcountAndRateTypeStackedColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<GenderHeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<GenderRatioChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseGenderHeadcountAndRateTypeStackedColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      GENDER_RATE_TYPES.toReversed().forEach((genderRateType, i) => {
        const code: GenderRatioChartSeriesCode = `${aggregateType}_${genderRateType}`;
        orderedSeriesCodes.push(code);
        lineMap.set(code, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            GenderRateTypeTextDef.get(genderRateType) as string
          )}`,
          color: super.getLineColor(aggregateType, i),
          dashStyle: super.getDashStyle(aggregateType),
        });
      });
      GENDER_HEADCOUNT_TYPES.filter((type) => type !== "all_headcount")
        .toReversed()
        .forEach((genderHeadcountType, i, array) => {
          const code: GenderHeadcountChartSeriesCode = `${aggregateType}_${genderHeadcountType}`;
          orderedSeriesCodes.push(code);
          columnMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              GenderHeadcountTypeTextDef.get(genderHeadcountType) as string
            )}`,
            color: super.getColumnColor(aggregateType, array.length, i),
          });
        });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseGenderHeadcountAndRateTypeStackedColumnLineData>
      | ChartQueryResult<BaseGenderHeadcountAndRateTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          ...GENDER_HEADCOUNT_TYPES.map((genderHeadcountType) => {
            return {
              header: t(GenderHeadcountTypeTextDef.get(genderHeadcountType) as string) as string,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType && datum.genderHeadcountAndRateType === genderHeadcountType
              ),
            };
          }),
          ...GENDER_RATE_TYPES.map((genderRateType) => {
            return {
              header: t(GenderRateTypeTextDef.get(genderRateType) as string) as string,
              unit: lineQueryResult.unit,
              values: super.getValuesByCondition(
                lineQueryResult,
                (datum) => datum.aggregateType === aggregateType && datum.genderHeadcountAndRateType === genderRateType
              ),
            };
          }),
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
