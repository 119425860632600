import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { MoreMenu } from "../../../components/more-menu";
import { MyTooltip } from "../../../components/my-tooltip";
import { NotFound } from "../../../components/not-found";
import {
  IndustryDivisionTextDef,
  IndustryMajorGroupTextDef,
  IndustryGroupTextDef,
  IndustryDetailTextDef,
} from "../../../config/text-def";
import { AuthStore } from "../../../stores/auth-store";
import { CompanyQueryResult, companyApi } from "../company-api";
import { CompanyDeleteDialog } from "../components/company-delete-dialog";
import { CompanyEditForm } from "../components/company-edit-form";
import { CompanyNewForm } from "../components/company-new-form";

export const Company = () => {
  const { t } = useTranslation();

  const [newFormOpen, setNewFormOpen] = useState<boolean>(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editTarget, setEditTarget] = useState<CompanyQueryResult>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<CompanyQueryResult>();

  const { isTenantOwner } = AuthStore.useContainer();

  const companiesQuery = companyApi.useCompanies();

  if (companiesQuery.isLoading)
    return (
      <TenantLayout activeKey="company">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!companiesQuery.data) return null;

  const onClickNew = () => {
    setNewFormOpen(true);
  };

  const onClickEdit = (company: CompanyQueryResult) => {
    setEditTarget(company);
    setEditFormOpen(true);
  };

  const onClickDelete = (company: CompanyQueryResult) => {
    setDeleteTarget(company);
    setDeleteDialogOpen(true);
  };

  const renderCompaniesContainer = () => (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MyTooltip unauthorized={!isTenantOwner}>
                <Button variant="contained" onClick={onClickNew} disabled={!isTenantOwner}>
                  {t("company.new")}
                </Button>
              </MyTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              {/* ヘッダーとボディをDataGridのデフォルトの高さに合わせる */}
              <TableHead>
                <TableRow sx={{ height: 39 }}>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("industry-division")}</TableCell>
                  <TableCell>{t("industry-major-group")}</TableCell>
                  <TableCell>{t("industry-group")}</TableCell>
                  <TableCell>{t("industry-detail")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companiesQuery.data.map((company) => (
                  <TableRow key={company.id} sx={{ height: 36 }}>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{t(IndustryDivisionTextDef.get(company.industryDivision) as string)}</TableCell>
                    <TableCell>{t(IndustryMajorGroupTextDef.get(company.industryMajorGroup) as string)}</TableCell>
                    <TableCell>
                      {company.industryGroup ? t(IndustryGroupTextDef.get(company.industryGroup) as string) : ""}
                    </TableCell>
                    <TableCell>
                      {company.industryDetail ? t(IndustryDetailTextDef.get(company.industryDetail) as string) : ""}
                    </TableCell>
                    <TableCell align="right" sx={{ py: 0 }}>
                      <MoreMenu>
                        <MyTooltip unauthorized={!isTenantOwner}>
                          <MenuItem disabled={!isTenantOwner} onClick={() => onClickEdit(company)}>
                            <EditIcon fontSize="small" sx={{ mr: 1 }} />
                            {t("edit")}
                          </MenuItem>
                        </MyTooltip>
                        <MyTooltip unauthorized={!isTenantOwner}>
                          <MenuItem
                            disabled={!isTenantOwner}
                            sx={{ color: (theme) => theme.palette.error.main }}
                            onClick={() => onClickDelete(company)}
                          >
                            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                            {t("delete")}
                          </MenuItem>
                        </MyTooltip>
                      </MoreMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );

  const renderNoCompanyContainer = () => (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <NotFound
            title={t("company.not-found")}
            caption={t("company.not-found.caption")}
            buttonLabel={t("company.new")}
            buttonUnauthorized={!isTenantOwner}
            disabled={!isTenantOwner}
            onClick={onClickNew}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <TenantLayout activeKey="company">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography variant="h6">{t("company")}</Typography>
            <Typography variant="caption">{t("company.caption")}</Typography>
          </Grid>
        </Grid>
        {companiesQuery.data.length ? renderCompaniesContainer() : renderNoCompanyContainer()}
      </Grid>
      {newFormOpen && <CompanyNewForm open={newFormOpen} onClose={() => setNewFormOpen(false)} />}
      {editFormOpen && editTarget && (
        <CompanyEditForm target={editTarget} open={editFormOpen} onClose={() => setEditFormOpen(false)} />
      )}
      {deleteDialogOpen && deleteTarget && (
        <CompanyDeleteDialog target={deleteTarget} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
    </TenantLayout>
  );
};
