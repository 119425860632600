import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";
import { DISABLED_OPACITY } from "../../../config/style";
import { SINGLE_YEAR_CHARTS } from "../config/const";
import { Chart } from "../types";

const StyledLink = styled((props: LinkProps) => <Link {...props} />)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
}));

type DashboardChartDetailLinkProps = {
  noData: boolean;
  chart: Chart;
};

export const DashboardChartDetailLink = ({ noData, chart }: DashboardChartDetailLinkProps) => {
  const { t } = useTranslation();

  // チャート一覧でフィルタが実装されるまでは、年齢分布に限りチャート詳細に遷移できるようにしておく
  // TODO: チャート一覧でのフィルタ実装後、削除する
  return (
    <StyledLink
      onClick={(e) => e.stopPropagation()}
      to={`/charts/${chart}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={!SINGLE_YEAR_CHARTS.includes(chart) && noData ? { pointerEvents: "none" } : undefined}
    >
      <Typography
        mr={0.5}
        sx={!SINGLE_YEAR_CHARTS.includes(chart) && noData ? { opacity: DISABLED_OPACITY } : undefined}
      >
        {t("link.detail")}
      </Typography>
      <OpenInNewIcon sx={!SINGLE_YEAR_CHARTS.includes(chart) && noData ? { opacity: DISABLED_OPACITY } : undefined} />
    </StyledLink>
  );
};
