import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../../components/dashboard-chart-display-switch";
import { ChartQueryResult } from "../../../../dashboard-api";
import { converters } from "../../../../functions/converters";
import { AggregateTypeRow } from "../../../../types";
import { BaseGenderTypeLineChart, BaseGenderTypeLineData } from "../base-gender-type-line-chart";

export class AverageAnnualBonusByGenderChart extends BaseGenderTypeLineChart {
  constructor(title: string, inBoard: boolean, t: TFunction) {
    super({ title, inBoard, unitCaption: t("dashboard.chart.unit-caption.thousand-yen") as string });
  }

  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGenderTypeLineData> | ChartQueryResult<BaseGenderTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    const newQueryResult = converters.toThousandYen<BaseGenderTypeLineData>(queryResult);
    return super.getChartOptions(t, newQueryResult, displaySwitch);
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGenderTypeLineData> | ChartQueryResult<BaseGenderTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    const newQueryResult = converters.toThousandYen<BaseGenderTypeLineData>(queryResult);
    return super.getAggregateTypeRows(t, newQueryResult, displaySwitch);
  }
}
