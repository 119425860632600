import { TFunction } from "i18next";
import { EMPLOYEE_GENDER_TYPES } from "../../../../config/const";
import { GenderEmployeeTypeTextDef } from "../../../../config/text-def";
import { CalcBasisQueryResult } from "../../../../dashboard-api";
import { CalcBasisBaseData, CalcBasisItemRow, EmployeeGenderType } from "../../../../types";
import { BaseCalcBasis } from "../../../base-calc-basis";

export type GenderPayGapCalcBasisData = {
  employeeGenderType: EmployeeGenderType;
} & CalcBasisBaseData;

export class GenderPayGapCalcBasis extends BaseCalcBasis<GenderPayGapCalcBasisData> {
  constructor(title: string, t: TFunction) {
    super(title, "", [t("dashboard.calc-basis.notes.gender-pay-gap")]);
  }

  getRows(
    t: TFunction,
    queryResult: CalcBasisQueryResult<GenderPayGapCalcBasisData> | CalcBasisQueryResult<GenderPayGapCalcBasisData>[]
  ): CalcBasisItemRow[] {
    if (Array.isArray(queryResult)) {
      return queryResult.map(({ calcBasisItem, unit }, index) => ({
        calcBasisItem,
        rows: EMPLOYEE_GENDER_TYPES.map((employeeGenderType) => {
          return {
            header: t(GenderEmployeeTypeTextDef.get(employeeGenderType) as string) as string,
            unit: unit,
            values: this.getValuesByCondition(
              queryResult[index],
              (datum) => datum.employeeGenderType === employeeGenderType
            ),
          };
        }),
      }));
    }
    throw new Error("CalcBasisQueryResult must be array.");
  }
}
