import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DatabaseIcon from "./icon/database-icon";
import { MyTooltip } from "./my-tooltip";

type NotFoundProps = {
  title: string;
  caption: string;
  buttonLabel: string;
  buttonUnauthorized?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const NotFound = ({
  title,
  caption,
  buttonLabel,
  buttonUnauthorized = false,
  disabled = false,
  onClick,
}: NotFoundProps) => {
  return (
    <Box my={4}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <DatabaseIcon />
        </Grid>
        <Grid item>
          <Box textAlign="center">
            <Typography>{title}</Typography>
            <Typography variant="caption">{caption}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <MyTooltip unauthorized={buttonUnauthorized}>
            <Button variant="contained" disabled={disabled} onClick={onClick}>
              {buttonLabel}
            </Button>
          </MyTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};
