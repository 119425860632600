import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../lib/axios";
import { MAX_RETRIES } from "../../lib/react-query";
import { CompanyType, IndustryDivision, IndustryDivisionOrAll } from "../../types";
import { BusinessYearVo, CompanyVo, TenantVo, UserVo } from "../../types/vo";
import { DashboardChartFilterFormModel } from "./components/dashboard-chart-filter-form";
import { getCompaniesCount, supplyAveragesInChart } from "./data/industry-averages";
import { BaseData, BoardType, CalcBasisBaseData, Chart, CalcBasisItem, ChartIndicator, Unit } from "./types";

export type BusinessYearQueryResult = BusinessYearVo;

export type SettingQueryResult = TenantVo;

export type CompanyQueryResult = CompanyVo;

export type CurrentUserQueryResult = UserVo;

export type DashboardChartGetRequest = {
  fromBusinessYearStartDate: string;
  toBusinessYearStartDate: string;
  companyType: CompanyType;
  companyIds: string[];
};

export type DashboardBoardQueryResult = {
  id: string;
  name: string;
  type: BoardType;
  charts: Chart[];
};

export type FilterQueryResult = {
  businessYears: BusinessYearVo[];
  companies: CompanyVo[];
};

export type ChartQueryResult<TData extends BaseData> = {
  chartIndicator: ChartIndicator;
  unit: Unit;
  datasets: {
    index: string; // 事業年度（2023年度）や年齢分布（20-24）など、データセットをまとめる単位
    data: TData[];
  }[];
};

export type CalcBasisQueryResult<TData extends CalcBasisBaseData> = {
  calcBasisItem: CalcBasisItem;
  unit: Unit;
  datasets: {
    index: string; // 事業年度（2023年度）や年齢分布（20-24）など、データセットをまとめる単位
    data: TData[];
  }[];
};

export type IndustryCompaniesCountQueryResult = {
  year: number;
  count: number;
};

// axios

const getBusinessYears = async (): Promise<BusinessYearQueryResult[]> => {
  return axiosInstance.get(`/business-years`);
};

const getCompanies = async (): Promise<CompanyQueryResult[]> => {
  return axiosInstance.get(`/companies`);
};

const getSetting = async (): Promise<SettingQueryResult> => {
  return axiosInstance.get("/setting");
};

const getBoards = async (): Promise<DashboardBoardQueryResult[]> => {
  return axiosInstance.get(`/dashboard/boards`);
};

const getBoard = async (id: string): Promise<DashboardBoardQueryResult> => {
  return axiosInstance.get(`/dashboard/boards/${id}`);
};

const getCurrentUser = (): Promise<CurrentUserQueryResult> => {
  return axiosInstance.get(`/users/current`);
};

const getCharts = async <TData extends BaseData>(
  chart: Chart,
  filter: DashboardChartGetRequest,
  industryDivision: IndustryDivision
): Promise<ChartQueryResult<TData> | ChartQueryResult<TData>[]> => {
  const result: ChartQueryResult<TData> = await axiosInstance.get(`/dashboard/charts/${chart}`, { params: filter });
  return supplyAveragesInChart(industryDivision, result);
};

const getFilters = async (chart: Chart): Promise<FilterQueryResult> => {
  return axiosInstance.get(`/dashboard/charts/${chart}/filters`);
};

const getCalcBases = async <TData extends CalcBasisBaseData>(
  chart: Chart,
  filter: DashboardChartGetRequest
): Promise<CalcBasisQueryResult<TData> | CalcBasisQueryResult<TData>[]> => {
  const result: CalcBasisQueryResult<TData> = await axiosInstance.get(`/dashboard/charts/${chart}/calc-bases`, {
    params: filter,
  });
  return result;
};

const getFiltersInBoard = async (): Promise<FilterQueryResult> => {
  return axiosInstance.get(`/dashboard/charts/gender_pay_gap/filters`);
};

// TODO: 以下のAPIを実装する
// パス: /dashboard/charts/${chart}/companies/count?industry={業種IDかall}&fromBusinessYearStartDate={事業年度開始日}&toBusinessYearStartDate={事業年度開始日}
// 返り値
// [
//   {
//     year: number, // 2023年
//     count: number
//   }
// ]
const getIndustryCompaniesCount = async (
  chart: Chart,
  fromBusinessYearStartDate: string,
  toBusinessYearStartDate: string,
  industryOrAll: IndustryDivisionOrAll = "all"
): Promise<IndustryCompaniesCountQueryResult[]> => {
  const count = getCompaniesCount(chart, industryOrAll);
  const fromYear = Number(fromBusinessYearStartDate.slice(0, 4));
  const toYear = Number(toBusinessYearStartDate.slice(0, 4));

  const results: IndustryCompaniesCountQueryResult[] = [];
  for (let year = fromYear; year <= toYear; year++) {
    results.push({ year, count });
  }
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(results);
    }, 100);
  });
};

// react-query

const useBusinessYears = () => {
  return useQuery({ queryKey: ["@dashboard", "business-years"], queryFn: getBusinessYears });
};

const useCompanies = () => {
  return useQuery({ queryKey: ["companies"], queryFn: getCompanies });
};

const useSetting = () => {
  return useQuery({ queryKey: ["@dashboard", "setting"], queryFn: getSetting });
};

const useBoards = () => {
  return useQuery({ queryKey: ["dashboard", "boards"], queryFn: () => getBoards() });
};

const useBoard = (id: string) => {
  return useQuery({ queryKey: ["dashboard", "boards", id], queryFn: () => getBoard(id) });
};

const useCurrentUser = () => {
  return useQuery({ queryKey: ["@dashboard", "users/current"], queryFn: () => getCurrentUser() });
};

const useCharts = <TData extends BaseData>(
  chart: Chart,
  filter?: DashboardChartFilterFormModel,
  industry?: IndustryDivision
) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, filter],
    queryFn: () => getCharts<TData>(chart, filter as DashboardChartFilterFormModel, industry as IndustryDivision),
    enabled: Boolean(filter) && Boolean(industry),
    retry: MAX_RETRIES,
  });
};

const useFilters = (chart: Chart) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "filters"],
    queryFn: () => getFilters(chart),
  });
};

const useCalcBases = <TData extends CalcBasisBaseData>(chart: Chart, filter?: DashboardChartFilterFormModel) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "calc-bases", filter],
    queryFn: () => getCalcBases<TData>(chart, filter as DashboardChartFilterFormModel),
    enabled: Boolean(filter),
    retry: MAX_RETRIES,
  });
};

const useFiltersInBoard = () => {
  return useQuery({
    queryKey: ["dashboard", "charts", "filters"],
    queryFn: () => getFiltersInBoard(),
  });
};

const useIndustryCompaniesCount = (
  chart?: Chart,
  industry?: IndustryDivision,
  fromBusinessYearStartDate?: string,
  toBusinessYearStartDate?: string
) => {
  return useQuery({
    queryKey: [
      "dashboard",
      "charts",
      chart,
      "companies",
      "count",
      industry,
      fromBusinessYearStartDate,
      toBusinessYearStartDate,
    ],
    queryFn: () =>
      getIndustryCompaniesCount(
        chart as Chart,
        fromBusinessYearStartDate as string,
        toBusinessYearStartDate as string,
        industry as IndustryDivision
      ),
    enabled:
      Boolean(chart) && Boolean(industry) && Boolean(fromBusinessYearStartDate) && Boolean(toBusinessYearStartDate),
  });
};

const useAllIndustryCompaniesCount = (
  chart: Chart,
  fromBusinessYearStartDate?: string,
  toBusinessYearStartDate?: string
) => {
  return useQuery({
    queryKey: ["dashboard", "charts", chart, "companies", "count", fromBusinessYearStartDate, toBusinessYearStartDate],
    queryFn: () =>
      getIndustryCompaniesCount(chart, fromBusinessYearStartDate as string, toBusinessYearStartDate as string),
    enabled: Boolean(fromBusinessYearStartDate) && Boolean(toBusinessYearStartDate),
  });
};

export const dashboardApi = {
  useBusinessYears,
  useCompanies,
  useSetting,
  useBoards,
  useBoard,
  useCurrentUser,
  useCharts,
  useFilters,
  useCalcBases,
  useFiltersInBoard,
  useIndustryCompaniesCount,
  useAllIndustryCompaniesCount,
};
