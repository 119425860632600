import Highcharts from "highcharts";
import { TFunction } from "i18next";
import { numberUtil } from "../../../utils/number";
import { DashboardChartDisplaySwitchModel } from "../components/dashboard-chart-display-switch";
import {
  CHART_HEIGHT_IN_BOARD,
  CHART_MARGIN_TOP_IN_BOARD,
  CHART_HEIGHT,
  CHART_MARGIN_TOP,
  AGGREGATE_TYPES,
} from "../config/const";
import { ChartUnitTextDef } from "../config/text-def";
import { ChartQueryResult } from "../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData } from "../types";
import { IBaseChart } from "./i-base-chart";

export type BaseChartProps = {
  title: string;
  inBoard: boolean;
  unitCaption?: string;
  notes?: string[];
};

export abstract class BaseChart<TData extends BaseData> implements IBaseChart<TData> {
  private _title: string;
  private _inBoard: boolean;
  private _unitCaption?: string;
  private _notes: string[];

  constructor({ title, inBoard, unitCaption, notes }: BaseChartProps) {
    this._title = title;
    this._inBoard = inBoard;
    this._unitCaption = unitCaption;
    this._notes = notes ?? [];
  }

  /**
   * 各チャートでベースとなるオプションを作成
   * 必要であれば呼び出しもとで上書きする（seriesの上書きは必須）
   */
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    // ツールチップに表示される値の桁区切りを設定
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    if (Array.isArray(queryResult)) {
      return {
        ...this._getChartOptions(t, queryResult[0], displaySwitch),
      };
    }
    return {
      ...this._getChartOptions(t, queryResult, displaySwitch),
    };
  }

  private _getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<TData>,
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    // Highchartsのthisを使うため、このインスタンスのthisをselfに格納
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      credits: { enabled: false },
      title: self._inBoard
        ? {
            text: self._title,
            style: {
              fontSize: "14px",
              fontWeight: "normal",
            },
            align: "left",
          }
        : { text: "" },
      chart: self._inBoard
        ? {
            height: CHART_HEIGHT_IN_BOARD,
            marginTop: CHART_MARGIN_TOP_IN_BOARD,
          }
        : {
            height: CHART_HEIGHT,
            marginTop: CHART_MARGIN_TOP,
          },
      //画面右の凡例表示
      responsive: self._inBoard
        ? {
            rules: [
              {
                condition: {
                  maxWidth: 530,
                },
                chartOptions: {
                  legend: {
                    enabled: false,
                  },
                },
              },
              {
                condition: {
                  minWidth: 531, // Windowsのブラウザでサイドメニューを閉じた時のチャート幅
                  maxWidth: 620,
                },
                chartOptions: {
                  legend: {
                    width: "26%", // 一番使われている凡例（女性（人数））を表示するための最小値
                  },
                },
              },
              {
                condition: {
                  minWidth: 621,
                },
                chartOptions: {
                  legend: {
                    width: 160, // 一番長い凡例（非正規雇用労働者）を表示するための最小値
                  },
                },
              },
            ],
          }
        : {},
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        itemStyle: {
          fontWeight: "normal",
        },
        // 棒グラフの凡例の形を四角形にする
        symbolWidth: 10,
        symbolHeight: 10,
        symbolRadius: 0,
      },
      xAxis: {
        categories: queryResult.datasets.map(({ index }) => index),
        tickLength: 0, // X軸の目盛線を非表示
        min: 0, // minとmaxを指定しないとnoDataの場合にX軸の目盛が表示されないため設定
        max: queryResult.datasets.length - 1,
      },
      yAxis: {
        title: { text: "" },
        min: 0,
        // 取得したデータが0だった場合、グラフの中央にy軸の0に対応する軸が描画されるため
        // `softMax: 1`を追加している。
        softMax: 1,
        labels: {
          formatter: function () {
            if (this.value === 0) {
              return `${this.value} (${t(ChartUnitTextDef.get(queryResult.unit) as string)})`;
            }
            return `${numberUtil.formatWithCommas(this.value as number)}`;
          },
        },
      },
      plotOptions: {
        series: {
          events: {
            // 凡例をクリックしてもグラフの表示・非表示をしない
            legendItemClick: () => false,
          },
          dataLabels: { enabled: displaySwitch.showDataLabel },
        },
      },
      tooltip: {
        shared: true,
      },
      lang: {
        noData: t("no-data") as string,
      },
      // series: 共通のものはないため、各チャートクラスで追加する
    };
  }

  noData(queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[]): boolean {
    if (Array.isArray(queryResult)) {
      return queryResult.every((q) => q.datasets.every((dataset) => dataset.data.length === 0));
    }
    return queryResult.datasets.every((dataset) => dataset.data.length === 0);
  }

  get unitCaption() {
    return this._unitCaption;
  }

  get notes() {
    return this._notes;
  }

  abstract getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[];

  /**
   * getAggregateTypeRowsの実装で利用する関数
   */
  protected getValuesByCondition<TData extends BaseData>(
    queryResult: ChartQueryResult<TData>,
    condition: (datum: TData) => boolean
  ): (number | null)[] {
    const indexes = queryResult.datasets.map(({ index }) => index);
    return indexes.map((index) => {
      const dataset = queryResult.datasets.find((dataset) => dataset.index === index);
      const datum = dataset?.data.find(condition);
      return datum ? datum.value : null;
    });
  }

  /**
   * DisplaySwitchの状態によって表示するAggregateTypeを絞り込み、業種や全業種の表示を制御する
   * getAggregateTypeRowsの実装で利用する関数
   */
  protected getFilteredAggregateTypes(displaySwitch: DashboardChartDisplaySwitchModel): AggregateType[] {
    return AGGREGATE_TYPES.filter(
      (aggregateType) =>
        aggregateType === "our_company" ||
        (aggregateType === "industry_average" && displaySwitch.showIndustryAverage) ||
        (aggregateType === "all_industry_average" && displaySwitch.showAllIndustryAverage)
    );
  }
}
