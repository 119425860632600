import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";
import {
  WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL_REFERENCE_DATE,
  WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL,
} from "../../../config/const";
import { dateUtil } from "../../../utils/date";
import { dashboardApi } from "../dashboard-api";

export const DashboardReference = () => {
  const currentUserQuery = dashboardApi.useCurrentUser();

  if (!currentUserQuery.data) return null;

  return (
    <Typography variant="caption">
      <Trans
        i18nKey="dashboard.reference.women-s-participation-and-advancement-database"
        values={{
          date: dateUtil.formatToUserLanguage(
            WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL_REFERENCE_DATE,
            currentUserQuery.data.language
          ),
          url: WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL,
        }}
        components={{
          l: (
            <a target="_blank" href={WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL} rel="noopener noreferrer">
              {WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL}
            </a>
          ),
        }}
        shouldUnescape
      />
    </Typography>
  );
};
