import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, GENDER_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, GenderTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption, GenderType } from "../../../types";
import { BaseColumnChart } from "../base-column-chart";

export type BaseGenderTypeColumnData = {
  genderType: GenderType;
} & BaseData;

export type BaseGenderTypeColumnChartSeriesCode = `${AggregateType}_${GenderType}`;

export class BaseGenderTypeColumnChart extends BaseColumnChart<BaseGenderTypeColumnData> {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGenderTypeColumnData> | ChartQueryResult<BaseGenderTypeColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDef(t)),
    };
  }

  protected _getSeriesCode(datum: BaseGenderTypeColumnData): BaseGenderTypeColumnChartSeriesCode {
    return `${datum.aggregateType}_${datum.genderType}`;
  }

  protected _createSeriesDef(t: TFunction): Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption> {
    const results = new Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption>();
    AGGREGATE_TYPES.forEach((aggregateType) => {
      GENDER_TYPES.forEach((genderType, i) => {
        results.set(`${aggregateType}_${genderType}`, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            GenderTypeTextDef.get(genderType) as string
          )}`,
          color: super.getColor(aggregateType, i),
        });
      });
    });
    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGenderTypeColumnData> | ChartQueryResult<BaseGenderTypeColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: GENDER_TYPES.map((genderType) => {
        return {
          header: t(GenderTypeTextDef.get(genderType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.genderType === genderType
          ),
        };
      }),
    }));
  }
}
