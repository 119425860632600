import { TFunction } from "i18next";
import { CalcBasisQueryResult } from "../../../dashboard-api";
import { CalcBasisBaseData, CalcBasisItemRow } from "../../../types";
import { BaseCalcBasis } from "../../base-calc-basis";

export class MaleChildcareLeaveRateCalcBasis extends BaseCalcBasis<CalcBasisBaseData> {
  getRows(
    t: TFunction,
    queryResult: CalcBasisQueryResult<CalcBasisBaseData> | CalcBasisQueryResult<CalcBasisBaseData>[]
  ): CalcBasisItemRow[] {
    if (Array.isArray(queryResult)) {
      return queryResult.map(({ calcBasisItem, unit }, index) => ({
        calcBasisItem,
        rows: [
          {
            unit: unit,
            values: this.getValuesByCondition(queryResult[index], (datum) => datum.calcBasisItem === calcBasisItem),
          },
        ],
      }));
    }
    throw new Error("CalcBasisQueryResult must be array.");
  }
}
