import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid/Grid";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { PLACEHOLDER_OPACITY } from "../../../config/style";
import { IndustryCompaniesCountQueryResult } from "../dashboard-api";
import { DashboardStore } from "../stores/dashboard-store";
import { AggregateType, DisplayValueUnionType } from "../types";
import { DashboardCompaniesCountTooltip } from "./dashboard-companies-count-tooltip";

export type DashboardChartDisplaySwitchModel = {
  selectedDisplayValues: string[];
  showIndustryAverage: boolean;
  showAllIndustryAverage: boolean;
  showDataLabel: boolean;
};

type DashboardChartDisplaySwitchProps = {
  model: DashboardChartDisplaySwitchModel;
  updateModel: (displaySwitch: DashboardChartDisplaySwitchModel) => void;
  displayValues?: Map<DisplayValueUnionType, string>;
  industryCompaniesCounts: IndustryCompaniesCountQueryResult[];
  allIndustryCompaniesCounts: IndustryCompaniesCountQueryResult[];
  inBoard: boolean;
  disabled: boolean;
};

export const DashboardChartDisplaySwitch = ({
  model,
  updateModel,
  displayValues,
  industryCompaniesCounts,
  allIndustryCompaniesCounts,
  inBoard,
  disabled,
}: DashboardChartDisplaySwitchProps) => {
  const { t } = useTranslation();
  const { selectedChartType } = DashboardStore.useContainer();

  const renderDisplayValueSelectorContainer = (displayValues: Map<DisplayValueUnionType, string>) => (
    <Grid container item alignItems="center">
      <Grid item mr={1}>
        <Typography>{t("dashboard.display-values")}</Typography>
      </Grid>
      <Grid item flexGrow={1}>
        <FormControl>
          <Select
            multiple
            displayEmpty
            value={model.selectedDisplayValues}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ opacity: PLACEHOLDER_OPACITY }}>{t("placeholder.select")}</Typography>;
              }
              return selected.map((s) => displayValues.get(s as DisplayValueUnionType)).join(", ");
            }}
            onChange={(e) => {
              updateModel({
                ...model,
                selectedDisplayValues: e.target.value as string[],
              });
            }}
            sx={{ bgcolor: "common.white", width: "260px" }}
          >
            {Array.from(displayValues.entries()).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                <Checkbox checked={model.selectedDisplayValues.includes(key)} />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const renderCheckboxContainer = (
    label: string,
    checked: boolean,
    disabled: boolean,
    inBoard: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    aggregateType?: AggregateType
  ) => (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        control={<Checkbox checked={checked} disabled={disabled} onChange={onChange} />}
        label={label}
        sx={aggregateType ? { mr: 0.5 } : undefined}
      />
      {aggregateType === "industry_average" && !inBoard && !disabled && (
        // Boardの場合やチェックボックスがdisabledの（業種/全業種平均の会社がない）場合は表示しない
        <DashboardCompaniesCountTooltip i18nKey="dashboard.industry-companies-count" data={industryCompaniesCounts} />
      )}
      {aggregateType === "all_industry_average" && !inBoard && !disabled && (
        <DashboardCompaniesCountTooltip
          i18nKey="dashboard.all-industry-companies-count"
          data={allIndustryCompaniesCounts}
        />
      )}
    </Box>
  );

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container spacing={2}>
          {selectedChartType === "graph" && displayValues && (
            <Grid item mr={2}>
              {renderDisplayValueSelectorContainer(displayValues)}
            </Grid>
          )}
          <Grid item mr={2}>
            {renderCheckboxContainer(
              t("dashboard.aggregate-type.industry-average"),
              model.showIndustryAverage,
              disabled || industryCompaniesCounts.every((c) => c.count === 0),
              inBoard,
              (e) => {
                updateModel({ ...model, showIndustryAverage: e.target.checked });
              },
              "industry_average"
            )}
          </Grid>
          <Grid item>
            {renderCheckboxContainer(
              t("dashboard.aggregate-type.all-industry-average"),
              model.showAllIndustryAverage,
              disabled || allIndustryCompaniesCounts.every((c) => c.count === 0),
              inBoard,
              (e) => {
                updateModel({ ...model, showAllIndustryAverage: e.target.checked });
              },
              "all_industry_average"
            )}
          </Grid>
        </Grid>
      </Grid>
      {selectedChartType === "graph" && (
        <Grid item>
          {renderCheckboxContainer(t("dashboard.chart.data-labels"), model.showDataLabel, disabled, inBoard, (e) => {
            updateModel({ ...model, showDataLabel: e.target.checked });
          })}
        </Grid>
      )}
    </Grid>
  );
};
