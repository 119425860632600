// ワークスペースのスラグがURLになるので、アプリで利用するパスを予約語として定義し、スラグとして登録できないようにする
export const RESERVED_SLUGS = [
  "workspaces",
  "members",
  "companies",
  "business-years",
  "settings",
  "my-workspaces",
  "boards",
  "charts",
];
export const SLUG_PATTERN = /^[a-z0-9-]*$/;
export const CHARACTER_LIMIT = {
  "80": 80,
} as const;
export const NONE = "-";
export const REFETCH_INTERVAL = 1000 * 30;
export const WORKSPACE_ID_HEADER_NAME = "x-hci-workspace-id";
export const COMPANY_TYPES = ["original", "current"] as const;
export const WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL =
  "https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/";
export const WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL_REFERENCE_DATE = "2023-07-24";
export const DATA_GRID_PAGE_SIZE = 20;
