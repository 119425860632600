import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledAccordion } from "../../../components/styled/styled-accordion";
import { StyledAccordionDetails } from "../../../components/styled/styled-accordion-detail";
import { StyledAccordionSummary } from "../../../components/styled/styled-accordion-summary";
import { IBaseCalcBasis } from "../classes/i-base-calc-basis";
import { dashboardApi } from "../dashboard-api";
import { Chart, CalcBasisBaseData } from "../types";
import { DashboardChartFilterFormModel } from "./dashboard-chart-filter-form";
import { DashboardTable } from "./dashboard-table";

type DashboardCalcBasisProps<TData extends CalcBasisBaseData, TCalcBasisClass extends IBaseCalcBasis<TData>> = {
  calcBasisClass: TCalcBasisClass;
  chart: Chart;
  filter?: DashboardChartFilterFormModel;
  isAllLoaded: boolean;
  onLoaded: () => void;
};

export const DashboardCalcBasis = <TData extends CalcBasisBaseData, TCalcBasisClass extends IBaseCalcBasis<TData>>({
  calcBasisClass,
  chart,
  filter,
  isAllLoaded,
  onLoaded,
}: DashboardCalcBasisProps<TData, TCalcBasisClass>) => {
  const { t } = useTranslation();

  const useCalcBasesQuery = dashboardApi.useCalcBases<TData>(chart, filter);

  useEffect(() => {
    if (!useCalcBasesQuery.isLoading && !useCalcBasesQuery.isFetching) {
      onLoaded();
    }
  }, [useCalcBasesQuery.isLoading, useCalcBasesQuery.isFetching, onLoaded]);

  if (!useCalcBasesQuery.data || !isAllLoaded) return null;

  return (
    <StyledAccordion sx={{ bgcolor: (theme) => theme.palette.grey[200], boxShadow: "none" }}>
      <StyledAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{
              fontSize: "0.9rem",
              backgroundColor: (theme) => theme.palette.grey[50],
              borderRadius: "100%",
              p: "0.2em",
              width: "1.2em",
              height: "1.2em",
            }}
          />
        }
      >
        <Grid container direction="column">
          <Grid item>
            <Typography>{t("dashboard.calc-basis")}</Typography>
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <DashboardTable
          columns={
            Array.isArray(useCalcBasesQuery.data)
              ? useCalcBasesQuery.data[0].datasets.map(({ index }) => index)
              : useCalcBasesQuery.data.datasets.map(({ index }) => index)
          }
          parentRows={calcBasisClass.getRows(t, useCalcBasesQuery.data)}
          unitCaption={calcBasisClass.unitCaption}
          notes={calcBasisClass.notes}
        />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
