import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, HeadcountAndRateTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  HeadcountAndRateType,
  HeadcountType,
  RateType,
} from "../../../types";
import { BaseColumnLineChart } from "../base-column-line-chart";

export type BaseHeadcountAndRateTypeColumnLineData = {
  headcountAndRateType: HeadcountAndRateType;
} & BaseData;

export type BaseHeadcountAndRateTypeColumnLineChartSeriesCode = `${AggregateType}_${HeadcountAndRateType}`;

type HeadcountChartSeriesCode = `${AggregateType}_${HeadcountType}`;
type RateChartSeriesCode = `${AggregateType}_${RateType}`;

export class BaseHeadcountAndRateTypeColumnLineChart extends BaseColumnLineChart<BaseHeadcountAndRateTypeColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRateTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRateTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(
    datum: BaseHeadcountAndRateTypeColumnLineData
  ): BaseHeadcountAndRateTypeColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.headcountAndRateType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<RateChartSeriesCode, ChartSeriesLineOption>,
    BaseHeadcountAndRateTypeColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<RateChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseHeadcountAndRateTypeColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      const rateChartSeriesCode: RateChartSeriesCode = `${aggregateType}_rate`;
      orderedSeriesCodes.push(rateChartSeriesCode);
      lineMap.set(rateChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          HeadcountAndRateTypeTextDef.get("rate") as string
        )}`,
        color: super.getLineColor(aggregateType, 0),
        dashStyle: super.getDashStyle(aggregateType),
      });

      const headcountChartSeriesCode: HeadcountChartSeriesCode = `${aggregateType}_headcount`;
      orderedSeriesCodes.push(headcountChartSeriesCode);
      columnMap.set(headcountChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          HeadcountAndRateTypeTextDef.get("headcount") as string
        )}`,
        color: super.getColumnColor(aggregateType, 0),
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRateTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRateTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          {
            header: t(HeadcountAndRateTypeTextDef.get("headcount") as string) as string,
            unit: columnQueryResult.unit,
            values: super.getValuesByCondition(
              columnQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.headcountAndRateType === "headcount"
            ),
          },
          {
            header: t(HeadcountAndRateTypeTextDef.get("rate") as string) as string,
            unit: lineQueryResult.unit,
            values: super.getValuesByCondition(
              lineQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.headcountAndRateType === "rate"
            ),
          },
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
