import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { IndustryCompaniesCountQueryResult } from "../dashboard-api";

type DashboardCompaniesCountTooltipProps = {
  i18nKey: string;
  data: IndustryCompaniesCountQueryResult[];
};

export const DashboardCompaniesCountTooltip = ({ i18nKey, data }: DashboardCompaniesCountTooltipProps) => {
  const { t } = useTranslation();

  const getTooltipTitle = () => (
    <>
      {data.map(({ year, count }) => (
        <Typography key={`${i18nKey}-${year}`}>{`${year} ${t(i18nKey, { number: count })}`}</Typography>
      ))}
    </>
  );

  return (
    <Tooltip title={getTooltipTitle()}>
      <HelpOutlineIcon fontSize="small" sx={{ color: (theme) => theme.palette.grey[500] }} />
    </Tooltip>
  );
};
