import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TenantLayout } from "../../../../../components/layout/tenant-layout";
import { Loading } from "../../../../../components/loading/loading";
import { BaseChartProps } from "../../../classes/base-chart";
import { AgeDistributionByGenderChart } from "../../../classes/column/binary-gender-type/age-distribution-by-gender/age-distribution-by-gender-chart";
import { BaseBinaryGenderTypeColumnData } from "../../../classes/column/binary-gender-type/base-binary-gender-type-column-chart";
import { DisabledEmploymentShortageHeadcountChart } from "../../../classes/column/disabled-employment-shortage-headcount/disabled-employment-shortage-headcount-chart";
import { AverageServiceYearByGenderChart } from "../../../classes/column/gender-type/average-service-year-by-gender/average-service-year-by-gender-chart";
import { BaseGenderTypeColumnData } from "../../../classes/column/gender-type/base-gender-type-column-chart";
import { RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-annual-hours-over-scheduled-working-hours/regular-employee-average-annual-hours-over-scheduled-working-hours-chart";
import { RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-monthly-hours-over-scheduled-working-hours/regular-employee-average-monthly-hours-over-scheduled-working-hours-chart";
import { AverageAnnualPaidLeaveConsumedDaysAndRateChart } from "../../../classes/column-line/days-and-rate/average-annual-paid-leave-consumed-days-and-rate/average-annual-paid-leave-consumed-days-and-rate-chart";
import { BaseDaysAndRateTypeColumnLineData } from "../../../classes/column-line/days-and-rate/base-days-and-rate-type-column-line-chart";
import { BaseHeadcountAndRateTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type/base-headcount-and-rate-type-column-line-chart";
import { ForeignEmployeeHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-employee-headcount-and-rate/foreign-employee-headcount-and-rate-chart";
import { ForeignExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-executive-headcount-and-rate/foreign-executive-headcount-and-rate-chart";
import { ForeignNewGraduateEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-new-graduate-employed-headcount-and-rate/foreign-new-graduate-employed-headcount-and-rate-chart";
import { MidCareerEmployedExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-executive-headcount-and-rate/mid-career-employed-executive-headcount-and-rate-chart";
import { MidCareerEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-headcount-and-rate/mid-career-employed-headcount-and-rate-chart";
import { BaseEmployeeTypeLineData } from "../../../classes/line/employee-type/base-employee-type-line-chart";
import { GenderPayGapChart } from "../../../classes/line/employee-type/gender-pay-gap/gender-pay-gap-chart";
import { MaleChildcareLeaveRateByEmployeeTypeChart } from "../../../classes/line/employee-type/male-childcare-leave-rate-by-employee-type/male-childcare-leave-rate-by-employee-type-chart";
import { FemaleManagerRatioChart } from "../../../classes/line/female-manager-ratio/female-manager-ratio-chart";
import { GenderAverageServiceYearGapChart } from "../../../classes/line/gender-average-service-year-gap/gender-average-service-year-gap-chart";
import { AverageAgeByGenderChart } from "../../../classes/line/gender-type/average-age-by-gender/average-age-by-gender-chart";
import { AverageAnnualBonusByGenderChart } from "../../../classes/line/gender-type/average-annual-bonus-by-gender/average-annual-bonus-by-gender-chart";
import { AverageAnnualSalaryByGenderChart } from "../../../classes/line/gender-type/average-annual-salary-by-gender/average-annual-salary-by-gender-chart";
import { BaseGenderTypeLineData } from "../../../classes/line/gender-type/base-gender-type-line-chart";
import { ChildcareLeaveRateByGenderChart } from "../../../classes/line/gender-type/childcare-leave-rate-by-gender/childcare-leave-rate-by-gender-chart";
import { AverageAnnualBonusByGradeTypeChart } from "../../../classes/line/grade-type/average-annual-bonus-by-grade-type/average-annual-bonus-by-grade-type-chart";
import { BaseGradeTypeLineData } from "../../../classes/line/grade-type/base-grade-type-line-chart";
import { MaleChildcareLeaveOrChildcarePurposeLeaveRateChart } from "../../../classes/line/male-childcare-leave-or-childcare-purpose-leave-rate/male-childcare-leave-or-childcare-purpose-leave-rate-chart";
import { MaleChildcareLeaveRateChart } from "../../../classes/line/male-childcare-leave-rate/male-childcare-leave-rate-chart";
import { BaseEmployeeTypeStackedColumnData } from "../../../classes/stacked-column/employee-type/base-employee-type-stacked-column-chart";
import { FullTimeEmployeeHeadcountByEmployeeTypeChart } from "../../../classes/stacked-column/employee-type/full-time-employee-headcount-by-employee-type/full-time-employee-headcount-by-employee-type-chart";
import { BaseGenderTypeStackedColumnData } from "../../../classes/stacked-column/gender-type/base-gender-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/foreign-employee-headcount-by-gender/foreign-employee-headcount-by-gender-chart";
import { FullTimeEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/full-time-employee-headcount-by-gender/full-time-employee-headcount-by-gender-chart";
import { BaseNationalityTypeStackedColumnData } from "../../../classes/stacked-column/nationality-type/base-nationality-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByNationalityChart } from "../../../classes/stacked-column/nationality-type/foreign-employee-headcount-by-nationality/foreign-employee-headcount-by-nationality-chart";
import { AnnualEmployedCompositionByGenderChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/annual-employed-composition-by-gender/annual-employed-composition-by-gender-chart";
import { BaseBinaryGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/binary-gender-composition-type/base-binary-gender-composition-type-stacked-column-line-chart";
import { ExecutiveGenderCompositionChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/executive-gender-composition/executive-gender-composition-chart";
import { BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/base-disabled-employment-headcount-and-rate-type-stacked-column-line-chart";
import { DisabledEmploymentHeadcountAndRateChart } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/disabled-employment-headcount-and-rate/disabled-employment-headcount-and-rate-chart";
import { AnnualEmployedCompositionByEmploymentTypeChart } from "../../../classes/stacked-column-line/employment-composition-type/annual-employed-composition-by-employment-type/annual-employed-composition-by-employment-type-chart";
import { BaseEmploymentCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/employment-composition-type/base-employment-composition-type-stacked-column-line-chart";
import { BaseGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-composition-type/base-gender-composition-type-stacked-column-line-chart";
import { GenderCompositionChart } from "../../../classes/stacked-column-line/gender-composition-type/gender-composition/gender-composition-chart";
import { BaseGenderHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/base-gender-headcount-and-rate-type-stacked-column-line-chart";
import { SeparatedHeadcountAndRateByGenderChart } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/separated-headcount-and-rate-by-gender/separated-headcount-and-rate-by-gender-chart";
import {
  DashboardChartDisplaySwitch,
  DashboardChartDisplaySwitchModel,
} from "../../../components/dashboard-chart-display-switch";
import { DashboardChartInBoard } from "../../../components/dashboard-chart-in-board";
import { DashboardChartSwitchButton } from "../../../components/dashboard-chart-switch-button";
import { DashboardReference } from "../../../components/dashboard-reference";
import { SINGLE_YEAR_CHARTS } from "../../../config/const";
import { BoardTypeTextDef, ChartTextDef } from "../../../config/text-def";
import { DashboardChartGetRequest, dashboardApi } from "../../../dashboard-api";
import { filters } from "../../../functions/filters";
import { DashboardStore } from "../../../stores/dashboard-store";
import { BaseData, Chart } from "../../../types";

export const DashboardBoardIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { industryCompaniesCounts, allIndustryCompaniesCounts, displaySwitchDisabled } = DashboardStore.useContainer();

  const [loadedCharts, setLoadedCharts] = useState<Chart[]>([]);
  // TODO: フィルタが実装されたら適用ボタンのdisabledを制御する
  const [isAllChartLoaded, setIsAllChartLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<DashboardChartGetRequest>();
  const [displaySwitch, setDisplaySwitch] = useState<DashboardChartDisplaySwitchModel>({
    selectedDisplayValues: [],
    showIndustryAverage: false,
    showAllIndustryAverage: false,
    showDataLabel: false,
  });
  const { selectedChartType } = DashboardStore.useContainer();

  const boardQuery = dashboardApi.useBoard(id);
  const useFiltersInBoardQuery = dashboardApi.useFiltersInBoard();

  useEffect(() => {
    if (useFiltersInBoardQuery.data) {
      // 事業年度が登録されていない場合、ルートパスの画面では「事業年度を登録してください」と出て画面から遷移はできないが、
      // 直接URLを入力して遷移した場合は事業年度が登録されていない時に限り、ルートパスにリダイレクトする
      if (useFiltersInBoardQuery.data.businessYears.length > 0) {
        setFilter({
          fromBusinessYearStartDate: filters.getFromBusinessYearStartDate(useFiltersInBoardQuery.data.businessYears),
          toBusinessYearStartDate: useFiltersInBoardQuery.data.businessYears[0].startDate,
          companyType: "original",
          companyIds: [],
        });
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFiltersInBoardQuery.data]);

  useEffect(() => {
    if (boardQuery.data) {
      setIsAllChartLoaded(loadedCharts.length === boardQuery.data.charts.length);
    }
  }, [boardQuery.data, loadedCharts]);

  if (boardQuery.isLoading || useFiltersInBoardQuery.isLoading)
    return (
      <TenantLayout activeKey="dashboard">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!boardQuery.data || !useFiltersInBoardQuery.data) return null;

  const onChartLoaded = (chart: Chart) => {
    setLoadedCharts((prev) => {
      // 既にロードされている場合は更新しない（これがないと無限ループになる）
      if (prev.includes(chart)) return prev;
      return [...prev, chart];
    });
  };

  const renderChart = (chart: Chart, inBoard: boolean) => {
    const title =
      t(ChartTextDef.get(chart) as string) +
      (SINGLE_YEAR_CHARTS.includes(chart)
        ? " ※" +
          useFiltersInBoardQuery.data.businessYears.find((year) => year.startDate === filter?.toBusinessYearStartDate)
            ?.name
        : "");
    const baseChartProps: BaseChartProps = {
      title,
      inBoard,
    };
    switch (chart) {
      case "gender_pay_gap":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, GenderPayGapChart>
            chartClass={new GenderPayGapChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "female_manager_ratio":
        return (
          <DashboardChartInBoard<BaseData, FemaleManagerRatioChart>
            chartClass={new FemaleManagerRatioChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "male_childcare_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveRateChart>
            chartClass={new MaleChildcareLeaveRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "male_childcare_leave_rate_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, MaleChildcareLeaveRateByEmployeeTypeChart>
            chartClass={new MaleChildcareLeaveRateByEmployeeTypeChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "male_childcare_leave_or_childcare_purpose_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveOrChildcarePurposeLeaveRateChart>
            chartClass={new MaleChildcareLeaveOrChildcarePurposeLeaveRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "gender_composition_of_all_employee":
      case "gender_composition_of_regular_employee":
      case "gender_composition_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseGenderCompositionTypeStackedColumnLineData, GenderCompositionChart>
            chartClass={new GenderCompositionChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "full_time_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, FullTimeEmployeeHeadcountByGenderChart>
            chartClass={new FullTimeEmployeeHeadcountByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "full_time_employee_headcount_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeStackedColumnData, FullTimeEmployeeHeadcountByEmployeeTypeChart>
            chartClass={new FullTimeEmployeeHeadcountByEmployeeTypeChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_age_of_all_employee_by_gender":
      case "average_age_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAgeByGenderChart>
            chartClass={new AverageAgeByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "age_distribution_of_regular_employee_by_gender":
      case "age_distribution_of_manager_by_gender":
        return (
          <DashboardChartInBoard<BaseBinaryGenderTypeColumnData, AgeDistributionByGenderChart>
            chartClass={new AgeDistributionByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_service_year_of_all_employee_by_gender":
      case "average_service_year_of_regular_employee_by_gender":
      case "average_service_year_of_non_regular_employee_by_gender":
      case "full_time_employee_average_service_year_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, AverageServiceYearByGenderChart>
            chartClass={new AverageServiceYearByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "gender_average_service_year_gap_of_all_employee":
      case "gender_average_service_year_gap_of_regular_employee":
      case "gender_average_service_year_gap_of_non_regular_employee":
      case "full_time_employee_gender_average_service_year_gap":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, GenderAverageServiceYearGapChart>
            chartClass={new GenderAverageServiceYearGapChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "annual_employed_composition_of_all_employee_by_gender":
      case "annual_employed_composition_of_regular_employee_by_gender":
      case "annual_employed_composition_of_non_regular_employee_by_gender":
      case "annual_new_graduate_employed_composition_of_regular_employee_by_gender":
      case "annual_mid_career_employed_composition_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseBinaryGenderCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByGenderChart
          >
            chartClass={new AnnualEmployedCompositionByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "annual_employed_composition_of_regular_employee_by_employment_type":
        return (
          <DashboardChartInBoard<
            BaseEmploymentCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByEmploymentTypeChart
          >
            chartClass={new AnnualEmployedCompositionByEmploymentTypeChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "mid_career_employed_headcount_and_rate_of_all_employee":
      case "mid_career_employed_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, MidCareerEmployedHeadcountAndRateChart>
            chartClass={new MidCareerEmployedHeadcountAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "mid_career_employed_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            MidCareerEmployedExecutiveHeadcountAndRateChart
          >
            chartClass={new MidCareerEmployedExecutiveHeadcountAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "separated_headcount_and_rate_of_regular_employee_by_gender":
      case "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderHeadcountAndRateTypeStackedColumnLineData,
            SeparatedHeadcountAndRateByGenderChart
          >
            chartClass={new SeparatedHeadcountAndRateByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "executive_gender_composition":
        return (
          <DashboardChartInBoard<BaseBinaryGenderCompositionTypeStackedColumnLineData, ExecutiveGenderCompositionChart>
            chartClass={new ExecutiveGenderCompositionChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "childcare_leave_rate_of_all_employee_by_gender":
      case "childcare_leave_rate_of_regular_employee_by_gender":
      case "childcare_leave_rate_of_non_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, ChildcareLeaveRateByGenderChart>
            chartClass={new ChildcareLeaveRateByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "foreign_employee_headcount_by_nationality":
        return (
          <DashboardChartInBoard<BaseNationalityTypeStackedColumnData, ForeignEmployeeHeadcountByNationalityChart>
            chartClass={new ForeignEmployeeHeadcountByNationalityChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "foreign_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, ForeignEmployeeHeadcountByGenderChart>
            chartClass={new ForeignEmployeeHeadcountByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "foreign_employee_headcount_and_rate_of_all_employee":
      case "foreign_employee_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignEmployeeHeadcountAndRateChart>
            chartClass={new ForeignEmployeeHeadcountAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "foreign_new_graduate_employed_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ForeignNewGraduateEmployedHeadcountAndRateChart
          >
            chartClass={new ForeignNewGraduateEmployedHeadcountAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "foreign_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignExecutiveHeadcountAndRateChart>
            chartClass={new ForeignExecutiveHeadcountAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "disabled_employment_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData,
            DisabledEmploymentHeadcountAndRateChart
          >
            chartClass={new DisabledEmploymentHeadcountAndRateChart(title, inBoard, t)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "disabled_employment_shortage_headcount":
        return (
          <DashboardChartInBoard<BaseData, DisabledEmploymentShortageHeadcountChart>
            chartClass={new DisabledEmploymentShortageHeadcountChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart>
            chartClass={new RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart>
            chartClass={new RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_annual_paid_leave_consumed_days_and_rate_of_all_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseDaysAndRateTypeColumnLineData, AverageAnnualPaidLeaveConsumedDaysAndRateChart>
            chartClass={new AverageAnnualPaidLeaveConsumedDaysAndRateChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_annual_salary_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualSalaryByGenderChart>
            chartClass={new AverageAnnualSalaryByGenderChart(title, inBoard, t)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualBonusByGenderChart>
            chartClass={new AverageAnnualBonusByGenderChart(title, inBoard, t)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_grade_type":
        return (
          <DashboardChartInBoard<BaseGradeTypeLineData, AverageAnnualBonusByGradeTypeChart>
            chartClass={new AverageAnnualBonusByGradeTypeChart(title, inBoard, t)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      default:
        throw new Error(`Invalid chart: ${chart}`);
    }
  };

  return (
    <TenantLayout activeKey="dashboard">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {boardQuery.data.name}
                    <Typography variant="caption" ml={1}>
                      {t(BoardTypeTextDef.get(boardQuery.data.type) as string)}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DashboardChartSwitchButton disabled={!isAllChartLoaded} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Grid container spacing={selectedChartType === "graph" ? 2 : 1}>
              <Grid item xs={12}>
                <DashboardChartDisplaySwitch
                  model={displaySwitch}
                  updateModel={setDisplaySwitch}
                  industryCompaniesCounts={industryCompaniesCounts}
                  allIndustryCompaniesCounts={allIndustryCompaniesCounts}
                  inBoard
                  disabled={!isAllChartLoaded || displaySwitchDisabled}
                />
              </Grid>
              {boardQuery.data.charts.map((chart) => (
                <Grid item xs={12} md={selectedChartType === "graph" ? 6 : 12} key={chart}>
                  {renderChart(chart, true)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item>
            <DashboardReference />
          </Grid>
        </Grid>
      </Grid>
    </TenantLayout>
  );
};
