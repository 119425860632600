import { IndustryDivision, IndustryDivisionOrAll } from "../../../types";
import { BaseEmployeeTypeLineData } from "../classes/line/employee-type/base-employee-type-line-chart";
import { EMPLOYEE_TYPES } from "../config/const";
import { ChartQueryResult } from "../dashboard-api";
import { BaseData, Chart } from "../types";

const HEADERS = [
  "gender_pay_gap_all",
  "gender_pay_gap_regular",
  "gender_pay_gap_non_regular",
  "female_manager_ratio",
  "male_childcare_leave_rate",
];
type Header = (typeof HEADERS)[number];

export const getCompaniesCount = (chart: Chart, industryOrAll: IndustryDivisionOrAll): number => {
  if (chart === "gender_pay_gap") return DATA["gender_pay_gap_all"][industryOrAll].companyNum;
  if (HEADERS.includes(chart)) return DATA[chart][industryOrAll].companyNum;
  return 0;
};

export const supplyAveragesInChart = <TData extends BaseData>(
  industryDivision: IndustryDivision,
  original: ChartQueryResult<TData>
): ChartQueryResult<TData> => {
  switch (original.chartIndicator) {
    case "gender_pay_gap":
      (original as unknown as ChartQueryResult<BaseEmployeeTypeLineData>).datasets.forEach((dataset) => {
        EMPLOYEE_TYPES.forEach((employeeType) => {
          dataset.data.push({
            aggregateType: "industry_average",
            employeeType,
            value: DATA[`gender_pay_gap_${employeeType}`][industryDivision].average,
          });
          dataset.data.push({
            aggregateType: "all_industry_average",
            employeeType,
            value: DATA[`gender_pay_gap_${employeeType}`]["all"].average,
          });
        });
      });
      return original;
    case "female_manager_ratio":
      (original as unknown as ChartQueryResult<BaseData>).datasets.forEach((dataset) => {
        dataset.data.push({
          aggregateType: "industry_average",
          value: DATA["female_manager_ratio"][industryDivision].average,
        });
        dataset.data.push({
          aggregateType: "all_industry_average",
          value: DATA["female_manager_ratio"]["all"].average,
        });
      });
      return original;
    case "male_childcare_leave_rate":
      (original as unknown as ChartQueryResult<BaseData>).datasets.forEach((dataset) => {
        dataset.data.push({
          aggregateType: "industry_average",
          value: DATA["male_childcare_leave_rate"][industryDivision].average,
        });
        dataset.data.push({
          aggregateType: "all_industry_average",
          value: DATA["male_childcare_leave_rate"]["all"].average,
        });
      });
      return original;
    default:
      return original;
  }
};

// 女性活躍推進データベース
// GSS https://docs.google.com/spreadsheets/d/1NNz3S854t8YlWIxogHD8zBxHficQFv7o/edit?pli=1#gid=712332427
const DATA: {
  [header in Header]: {
    [industry in IndustryDivisionOrAll]: { companyNum: number; average: number };
  };
} = {
  gender_pay_gap_all: {
    all: {
      companyNum: 3391,
      average: 69.0,
    },
    A: {
      companyNum: 7,
      average: 68.8,
    },
    B: {
      companyNum: 0,
      average: 0,
    },
    C: {
      companyNum: 5,
      average: 65.3,
    },
    D: {
      companyNum: 166,
      average: 65.3,
    },
    E: {
      companyNum: 1000,
      average: 69.3,
    },
    F: {
      companyNum: 35,
      average: 66.9,
    },
    G: {
      companyNum: 287,
      average: 75.9,
    },
    H: {
      companyNum: 155,
      average: 68.1,
    },
    I: {
      companyNum: 499,
      average: 63.3,
    },
    J: {
      companyNum: 211,
      average: 57.5,
    },
    K: {
      companyNum: 73,
      average: 63.3,
    },
    L: {
      companyNum: 58,
      average: 69.1,
    },
    M: {
      companyNum: 64,
      average: 68.8,
    },
    N: {
      companyNum: 26,
      average: 68.9,
    },
    O: {
      companyNum: 47,
      average: 68.6,
    },
    P: {
      companyNum: 303,
      average: 79.3,
    },
    Q: {
      companyNum: 47,
      average: 68.1,
    },
    R: {
      companyNum: 375,
      average: 72.5,
    },
    S: {
      companyNum: 10,
      average: 69.9,
    },
    T: {
      companyNum: 23,
      average: 68.1,
    },
  },
  gender_pay_gap_non_regular: {
    all: {
      companyNum: 3237,
      average: 75.6,
    },
    A: {
      companyNum: 7,
      average: 86.3,
    },
    B: {
      companyNum: 0,
      average: 0,
    },
    C: {
      companyNum: 5,
      average: 56.2,
    },
    D: {
      companyNum: 157,
      average: 58.3,
    },
    E: {
      companyNum: 957,
      average: 70.1,
    },
    F: {
      companyNum: 34,
      average: 62.3,
    },
    G: {
      companyNum: 249,
      average: 75.4,
    },
    H: {
      companyNum: 150,
      average: 73.8,
    },
    I: {
      companyNum: 483,
      average: 81.2,
    },
    J: {
      companyNum: 205,
      average: 64,
    },
    K: {
      companyNum: 71,
      average: 68.4,
    },
    L: {
      companyNum: 53,
      average: 75.4,
    },
    M: {
      companyNum: 62,
      average: 88.9,
    },
    N: {
      companyNum: 25,
      average: 91.6,
    },
    O: {
      companyNum: 46,
      average: 88.4,
    },
    P: {
      companyNum: 294,
      average: 96,
    },
    Q: {
      companyNum: 46,
      average: 79.1,
    },
    R: {
      companyNum: 362,
      average: 78.2,
    },
    S: {
      companyNum: 10,
      average: 79.2,
    },
    T: {
      companyNum: 21,
      average: 77.7,
    },
  },
  gender_pay_gap_regular: {
    all: {
      companyNum: 3237,
      average: 74.3,
    },
    A: {
      companyNum: 7,
      average: 80.9,
    },
    B: {
      companyNum: 0,
      average: 0,
    },
    C: {
      companyNum: 5,
      average: 67.7,
    },
    D: {
      companyNum: 165,
      average: 68.4,
    },
    E: {
      companyNum: 999,
      average: 73.9,
    },
    F: {
      companyNum: 35,
      average: 72.4,
    },
    G: {
      companyNum: 286,
      average: 78.1,
    },
    H: {
      companyNum: 154,
      average: 76.1,
    },
    I: {
      companyNum: 499,
      average: 71.6,
    },
    J: {
      companyNum: 211,
      average: 62.7,
    },
    K: {
      companyNum: 73,
      average: 67.3,
    },
    L: {
      companyNum: 58,
      average: 75.4,
    },
    M: {
      companyNum: 64,
      average: 77.1,
    },
    N: {
      companyNum: 26,
      average: 76.3,
    },
    O: {
      companyNum: 47,
      average: 77.7,
    },
    P: {
      companyNum: 302,
      average: 82.4,
    },
    Q: {
      companyNum: 47,
      average: 75.6,
    },
    R: {
      companyNum: 373,
      average: 77.6,
    },
    S: {
      companyNum: 10,
      average: 77.2,
    },
    T: {
      companyNum: 23,
      average: 75.3,
    },
  },
  female_manager_ratio: {
    all: {
      companyNum: 6176,
      average: 15.2,
    },
    A: {
      companyNum: 12,
      average: 9.9,
    },
    B: {
      companyNum: 0,
      average: 0,
    },
    C: {
      companyNum: 7,
      average: 3.9,
    },
    D: {
      companyNum: 281,
      average: 3.6,
    },
    E: {
      companyNum: 1731,
      average: 6.1,
    },
    F: {
      companyNum: 46,
      average: 3.6,
    },
    G: {
      companyNum: 481,
      average: 10.9,
    },
    H: {
      companyNum: 280,
      average: 6.2,
    },
    I: {
      companyNum: 897,
      average: 11.7,
    },
    J: {
      companyNum: 295,
      average: 16.5,
    },
    K: {
      companyNum: 112,
      average: 10.4,
    },
    L: {
      companyNum: 122,
      average: 14.1,
    },
    M: {
      companyNum: 147,
      average: 13.4,
    },
    N: {
      companyNum: 58,
      average: 23.3,
    },
    O: {
      companyNum: 96,
      average: 28.4,
    },
    P: {
      companyNum: 754,
      average: 47.8,
    },
    Q: {
      companyNum: 72,
      average: 14.6,
    },
    R: {
      companyNum: 729,
      average: 17.6,
    },
    S: {
      companyNum: 12,
      average: 19.1,
    },
    T: {
      companyNum: 44,
      average: 12.6,
    },
  },
  male_childcare_leave_rate: {
    all: {
      companyNum: 7910,
      average: 32.2,
    },
    A: {
      companyNum: 17,
      average: 27.1,
    },
    B: {
      companyNum: 0,
      average: 0,
    },
    C: {
      companyNum: 8,
      average: 40.1,
    },
    D: {
      companyNum: 364,
      average: 33,
    },
    E: {
      companyNum: 2217,
      average: 33.7,
    },
    F: {
      companyNum: 55,
      average: 49.7,
    },
    G: {
      companyNum: 596,
      average: 46.2,
    },
    H: {
      companyNum: 397,
      average: 24,
    },
    I: {
      companyNum: 1184,
      average: 25.3,
    },
    J: {
      companyNum: 329,
      average: 62.2,
    },
    K: {
      companyNum: 152,
      average: 45,
    },
    L: {
      companyNum: 149,
      average: 45.9,
    },
    M: {
      companyNum: 194,
      average: 18.3,
    },
    N: {
      companyNum: 77,
      average: 26.1,
    },
    O: {
      companyNum: 133,
      average: 24.6,
    },
    P: {
      companyNum: 939,
      average: 25.6,
    },
    Q: {
      companyNum: 89,
      average: 20.6,
    },
    R: {
      companyNum: 943,
      average: 28,
    },
    S: {
      companyNum: 12,
      average: 48.6,
    },
    T: {
      companyNum: 55,
      average: 33,
    },
  },
};
