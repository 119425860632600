import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChartTextDef } from "../../config/text-def";
import { Chart } from "../../types";
import { DashboardChartSwitchButton } from "../dashboard-chart-switch-button";

export const CHART_HEADER_HEIGHT = 48;

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: `${CHART_HEADER_HEIGHT}px`,
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(2),
}));

export const DashboardChartHeader = () => {
  const { t } = useTranslation();
  const { id: chart } = useParams() as { id: Chart };
  const navigate = useNavigate();

  return (
    <StyledPaper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={() => navigate("/")}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">{t(ChartTextDef.get(chart) as string)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DashboardChartSwitchButton />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};
